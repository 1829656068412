<template>
    <div class="d-flex">
        <section class="w-50" style="padding-right: 8%">
            <h1 class="mb-2">{{$t('navigation.users')}}</h1>
            <FormItem type="text" v-model="item.first_name" :label="$t('form.first_name')" sizeItem="6" sizeLabel="6" :required="true" :disabled="disableAll" />
            <FormItem type="text" v-model="item.last_name" :label="$t('form.last_name')" sizeItem="6" sizeLabel="6" :required="true" :disabled="disableAll" />
            <FormItem type="email" v-model="item.email" :label="$t('form.email')" sizeItem="6" sizeLabel="6" :required="true" :disabled="disableAll" />

            <FormItem type="select" v-model="item.user_type_id" :options="userTypes" :label="$t('overview.type')" :placeholder="$t('form.select')" sizeItem="6" sizeLabel="6" v-if="!disableAll" :required="true" />
            <FormItem type="text" v-model="userTypeHardCoded" :label="$t('overview.type')" sizeItem="6" sizeLabel="6" :disabled="true" v-else />

            <template v-if="item.user_type_id == 3"> <!-- Dealer -->
                <FormItem type="select" v-model="item.dealer_id" :options="dealers" :label="$t('navigation.dealers')" :placeholder="$t('form.select')" sizeItem="6" sizeLabel="6" :disabled="dealerId" />
            </template>
            <template v-if="item.user_type_id == 2"> <!-- Klant -->
                <FormItem id="sl1" type="slider" v-model="item.can_finalise_daystate" :label="'Finaliseren dagstaat'" sizeItem="6" sizeLabel="6" :options="[1,0]" :disabled="disableAll" />
                <FormItem id="sl2" type="slider" v-model="item.can_see_safe_mutations" :label="'Toon kluismutaties'" sizeItem="6" sizeLabel="6" :options="[1,0]" :disabled="disableAll" />
                <FormItem id="sl3" type="slider" v-model="item.can_see_bank_deposit" :label="'Toon bankstorting'" sizeItem="6" sizeLabel="6" :options="[1,0]" :disabled="disableAll" />
            </template>
        </section>
        <section class="w-50">
            <h2 class="pt-2 pb-1">{{$tc('form.user_rights', 2)}}</h2>
            <template v-for="(access, aindex) in item.user_accesses" :key="aindex">
                <div class="d-flex mt-2 gap-3" v-if="!access.access_id_string || accessOptions.groups.find(group => group.access_id_string == access.access_id_string)">
                    <div class="col-6 col-select">
                        <select v-model="access.access_id_string" :disabled="disableAll">
                            <option v-for="option in filterAccesses(accessOptions.groups, access.access_id_string)" :key="option.id" :value="option.access_id_string">{{option.name}}</option>
                        </select>
                    </div>
                    <div class="col-5 col-select">
                        <select v-model="access.user_access_group_id" :disabled="disableAll">
                            <option v-for="option in accessOptions.accesses" :key="option.id" :value="option.id">{{option.name}}</option>
                        </select>
                    </div>
                    <div class="col-auto">
                        <button type="button" class="btn" @click="removeUserAccess(aindex)" :disabled="disableAll">
                            <i class="fa fa-close text-danger"></i>
                        </button>
                    </div>
                </div>
            </template>
            <button type="button" class="btn btn-unilight px-4 mt-4" :disabled="isSaving || disableAll" @click="addUserAccess">
                <i class="far fa-plus me-2"></i>{{$t('form.add', [$tc('form.user_rights', 1)])}}
            </button>
        </section>
    </div>
    <FooterButtons @prev="prev" @save="save" :disabled="isSaving || disableAl" />
</template>

<script>
    import dealerService from '@/services/DealerService';
    import userService from '@/services/UserService';
    import FooterButtons from '@/components/FooterButtons.vue';
    import store from '@/store/user';

    export default {
        name: 'UserDetail',
        components: {
            FooterButtons
        },
        data() {
            return {
                item: {
                    "email": "",
                    "name": "",
                    "dealer_id": 1,
                    "first_name": "",
                    "last_name": "",
                    "user_type_id": null,
                    "user_accesses": [],
                    "can_finalise_daystate": 0,
                    "can_see_safe_mutations": 0,
                    "can_see_bank_deposit": 0
                },
                dealers: [],
                accessOptions: {groups: [], accesses: []},
                userTypes: [],
                userTypeHardCoded: '',
                dealerId: null,
                isNew: true,
                isSaving: false,
                disableAll: false
            }
        },
        methods: {
            validate(){
                //Validation
                const els = document.querySelectorAll("section input[type='text'], section input[type='email'], section select");
                let valid = true;
                const placeholderValue = this.$t('form.select');
                els.forEach(el => {
                    if(!el.value || el.value == placeholderValue){
                        el.classList.add("is-invalid");
                        if(!el.classList.contains("has-onfocus")){
                            el.addEventListener("focus", (thisEl) => {
                                thisEl.target.classList.remove("is-invalid");
                            })
                            el.classList.add("has-onfocus");
                        }
                        valid = false;
                    }
                });
                if(valid){
                    this.item.user_accesses.forEach(ua => {
                        const accessdata = ua.access_id_string.split(',');
                        ua.hospitality_group_id = accessdata[0] == 'null' ? null : accessdata[0];
                        ua.franchise_id = accessdata[1] == 'null' ? null : accessdata[1];
                        ua.location_id = accessdata[2] == 'null' ? null : accessdata[2];
                    });
                }
                return valid;
            },
            prev() {
                this.$router.push({'name': "Gebruikers"});
            },
            save() {
                let hasLinesRemoved = false;
                const length = this.item.user_accesses.length - 1;
                for(let i = length; i >= 0; i--){
                    if(!this.item.user_accesses[i].access_id_string && !this.item.user_accesses[i].user_access_group_id){
                        this.item.user_accesses.splice(i, 1);
                        hasLinesRemoved = true;
                    }
                }
                if(hasLinesRemoved){
                    this.$nextTick(() => this.save());
                    return;
                }
                if(!this.validate()){
                    return;
                }
                this.isSaving = true;
                this.item.name = this.item.first_name + ' ' + this.item.last_name;
                userService.updateOrStore(this.item).then(response => {
                    if(response.data.id == store.getters.userId){
                        window.location.href = window.location.origin; //Redirect to Home
                    }
                    else{
                        this.$router.push({'name': "Gebruikers"}); //Redirect to overview
                    }
                }).catch(e => {
                    this.isSaving = false;
                    this.toastError(e);
                });
            },
            addUserAccess(){
                this.item.user_accesses.push({
                    "user_id": this.item.id,
                    "user_access_group_id": null,
                    "hospitality_group_id": null,
                    "franchise_id": null,
                    "location_id": null,
                    "access_id_string": null
                })
            },
            removeUserAccess(index){
                this.item.user_accesses.splice(index, 1);
            },
            filterAccesses(list, id){
                const itemsInUse = this.item.user_accesses.map(ua => ua.access_id_string);
                return list.filter(opt => opt.access_id_string == id || !itemsInUse.includes(opt.access_id_string));
            }
        },
        mounted(){
            const locationId = this.$route.params.id;
            this.isNew = locationId == 'nieuw';
            this.dealerId = store.getters.getUserType == 3 ? store.getters.getUser.dealer_id : null;
            //Not customers allowed
            if(store.getters.getUserType == 2){
                this.$router.push({name: 'Home'});
            }
            if(!this.isNew){
                userService.show(locationId).then(response => {
                    this.item = response.data;
                    const sname = this.item.name;
                    this.item.first_name = sname.replace(/\s.+/, '');
                    this.item.last_name = sname.replace(/^.+?\s/, '');
                    this.item.user_accesses.forEach(opt => opt.access_id_string = opt.hospitality_group_id + ',' + opt.franchise_id + ',' + opt.location_id);
                    this.disableAll = this.dealerId && (this.item.user_type_id == 1 || (this.item.user_type_id == 3 && this.item.dealer_id != this.dealerId));
                    this.userTypeHardCoded = this.$t('user_types.'+this.item.user_type_id);
                });
            }
            else if(this.dealerId){
                this.item.dealer_id = this.dealerId;
            }
            dealerService.index().then(response => {
                this.dealers = response.data;
            });
            userService.accessOptions().then(response => {
                this.accessOptions = response.data;
                this.accessOptions.groups.forEach(opt => opt.access_id_string = opt.hospitality_group_id + ',' + opt.franchise_id + ',' + opt.location_id);
                this.item.user_accesses.forEach(opt => {
                    let found = this.accessOptions.groups.findIndex(group => group.access_id_string == opt.access_id_string);
                    opt.sortIndex = found ? found : 0;
                });
                this.item.user_accesses.sort((a, b) => a.sortIndex > b.sortIndex ? 1 : -1);
            });
            let iteratorStart = this.dealerId ? 2 : 1;
            for(let i = iteratorStart; i <= 3; i++){
                this.userTypes.push({
                    id: i,
                    name: this.$t('user_types.'+i)
                });
            }
            this.userTypes.sort((a, b) => a.name > b.name ? 1 : -1);
        }
    }

</script>